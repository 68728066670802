(function ($) {
  Drupal.behaviors.productDisclaimer = {
    attach: function (context, settings) {
      var $disclaimers = $('.js-content-block__disclaimer', context);

      $disclaimers.each((index, elContext) => {
        var $title = $('.js-accordion__title', elContext);
        var $panel = $('.js-accordion__panel', elContext);
        var params = {
          titles: $title,
          panels: $panel,
          panelToShow: $panel.hasClass('init--open') ? 0 : undefined
        };
        new site.BasicAccordion(params);
      });
    }
  };
})(jQuery);
